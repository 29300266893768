import React, { useEffect, useRef, useState } from "react";
import UseDebounce from "../../shared/hooks/UseDebonce";
import axiosClient from "../../api/axiosClient";
import { useNavigate } from "react-router-dom";
import { VideoSearchIcon } from "../../assets/icons";
import axios from "axios";

const MainSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState({
    videos: [],
    lessons: [],
    users: [],
  });
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = UseDebounce(searchTerm, 500);
  const resultsRef = useRef(null);
  const navigate = useNavigate();
  const controller = useRef();

  const filterData = async (inputValue) => {
    if (!inputValue) return;

    const apiUrl = "https://eduapi.siesco.az/api/UserVideoAndLesson/GeneralSearch";
    const token = localStorage.getItem("authState");
    const parsedToken = JSON.parse(token);

    if (controller.current) {
      controller.current.abort();
    }

    controller.current = new AbortController();
    const signal = controller.current.signal;

    setLoading(true);  // Start loading state
    setSearchData({ videos: [], lessons: [], users: [] });
    setNoData(false); // Reset noData to false when starting a new request

    try {
      const response = await axios.get(apiUrl, {
        params: { title: inputValue },
        headers: {
          "Content-Type": "application/json",
          ...(token ? { Authorization: `Bearer ${parsedToken.accessToken}` } : {}),
        },
        signal,
      });

      if (response.status === 200) {
        setSearchData({
          videos: response?.data?.videos || [],
          lessons: response?.data?.lessons || [],
          users: response?.data?.users || [],
        });

        setNoData(
          !response?.data?.videos?.length &&
          !response?.data?.lessons?.length &&
          !response?.data?.users?.length
        );
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching filtered data:", error);
        setSearchData({ videos: [], lessons: [], users: [] });
        setNoData(true);
      }
    } finally {
      setLoading(false);  // End loading state after request completes
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      filterData(debouncedSearchTerm);
    } else {
      setSearchData({ videos: [], lessons: [], users: [] });
      setNoData(false);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setSearchTerm("");
        setSearchData({ videos: [], lessons: [], users: [] });
        setNoData(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const allResults = [
    ...searchData.videos.map((video) => ({ type: "video", ...video })),
    ...searchData.lessons.map((lesson) => ({ type: "lesson", ...lesson })),
    ...searchData.users.map((user) => ({ type: "user", ...user })),
  ];

  const handlePass = (item) => {
    switch (item.type) {
      case "video":
        navigate("/", { state: { videoData: item } });
        break;
      case "lesson":
        navigate("/documents", { state: { lessonData: item } });
        break;
      case "user":
        navigate(`/details/${item.id}`);
        break;
      default:
        console.warn("Unknown type:", item.type);
    }
    setSearchTerm("");
    setSearchData({ videos: [], lessons: [], users: [] });
    setNoData(false);
  };

  return (
    <div className="searchDiv" ref={resultsRef}>
      <div className="search">
        <input
          type="text"
          placeholder="Axtar"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setLoading(true); // Set loading when input changes
          }}
        />
        <i className="fa fa-search"></i>
      </div>
      <div className={`resultsSearch ${searchTerm === "" ? "empty" : ""}`}>
        <div className="result-items">
          {loading ? (  // Show loader when loading is true
            <div className="loaderSearch"></div>  // Loading animation
          ) : allResults?.length > 0 ? (  // Show results if available
            allResults.map((item) => (
              <div
                key={item.id}
                className="result-item"
                onClick={() => handlePass(item)}
              >
                <p>
                  {item.type === "video" ? (
                    <VideoSearchIcon />
                  ) : item.type === "lesson" ? (
                    <i className="fa-solid fa-book" style={{ fontSize: "13px" }}></i>
                  ) : (
                    <i className="fa fa-user" style={{ fontSize: "13px" }}></i>
                  )}
                  {item.title || item.name}
                </p>
              </div>
            ))
          ) : !loading && noData ? (  // Show "No Data Found" when not loading and no data exists
            <p className="searchNoData">Məlumat Tapılmadı</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MainSearch;
