import React, { createContext, useState, useEffect } from 'react';
import axiosClient from '../api/axiosClient';
import * as signalR from '@microsoft/signalr';

export const AuthContext = createContext();

const getAuthFromStorage = () => {
  const authState = localStorage.getItem('authState');
  return authState ? JSON.parse(authState) : {
    accessToken: null,
    refreshToken: null,
    userFullName: null,
    userStatus: null,
    photo: null,
  };
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(getAuthFromStorage);
  const [connection, setConnection] = useState(null);
  const [ActiveUsers, SetActiveUsers] = useState([]);

  useEffect(() => {
    if (auth.accessToken) {
      axiosClient.defaults.headers.common['Authorization'] = `Bearer ${auth.accessToken}`;
    }
  }, [auth.accessToken]);

  useEffect(() => {
    if (auth.accessToken) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl("https://eduapi.siesco.az/userHub", {
          accessTokenFactory: () => auth.accessToken,
        })
        .withAutomaticReconnect()
        .build();
      newConnection
        .start()
        .then(() => {
          setConnection(newConnection);
          // console.log("SignalR Connected!");

          newConnection.on("AktivUsers", (myUser) => {
            SetActiveUsers(myUser);
          });
        })
        .catch(err => console.error("SignalR Connection Error: ", err));

      return () => {
        newConnection.stop();
      };
    }
  }, [auth.accessToken]);

  useEffect(() => {
    const interceptor = axiosClient.interceptors.response.use(
      response => response,
      async error => {
        if (error.response && error.response.status === 401) {
       
          try {
            const response = await axiosClient.post('/auth/refresh', {
              refreshToken: auth.refreshToken,
            });

            const updatedAuth = {
              ...auth,
              accessToken: response.data.accessToken,
            };

            localStorage.setItem('authState', JSON.stringify(updatedAuth));
            setAuth(updatedAuth);

            error.config.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
            return axiosClient(error.config);

          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            logout();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosClient.interceptors.response.eject(interceptor);
    };
  }, [auth, connection]);

  const login = async (email, password) => {
    try {
      const response = await axiosClient.post('/auth/login', { email, password });
      const newAuth = {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        userFullName: response.data.userFullName,
        userStatus: response.data.userStatus,
        photo: response.data.photo
      };
      localStorage.setItem('authState', JSON.stringify(newAuth));
      setAuth(newAuth);
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('authState');
    setAuth({
      accessToken: null,
      refreshToken: null,
      userFullName: null,
      userStatus: null,
      photo: null
    });
    if (connection) {
      connection.stop();
    }
  };

  const updateUser = (updates) => {
    const updatedAuth = { ...auth, ...updates };
    localStorage.setItem('authState', JSON.stringify(updatedAuth));
    setAuth(updatedAuth);
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, updateUser, ActiveUsers }}>
      {children}
    </AuthContext.Provider>
  );
};
