import React, { useContext, useState } from "react";
import "../Login.css";
import logo from "../images/logo white-01 1.png";
import { AuthContext } from "../context/AuthContext"; // AuthContext import edilmesi
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "../api/axiosClient";
import errorImg from "../images/circle-exclamation.png";
import {
  CancelIcon,
  CheckIcon,
  CloseEyeIcon,
  LockIcon,
  MailIcon,
  OpenEyeIcon,
} from "../assets/icons";

function AddPassword() {
  const { login } = useContext(AuthContext); // login fonksiyonunu context'ten alıyoruz
  const navigate = useNavigate();
  const { token } = useParams();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordFirst, setPasswordFirst] = useState("");
  const [passwordSecond, setPasswordSecond] = useState("");
  const [showPasswordFirst, setShowPasswordFirst] = useState(false);
  const [showPasswordSecond, setShowPasswordSecond] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    axiosClient
      .post("/Auth/AddPasswordForNewUser", {
        email: e.target.email.value,
        token: token.replace(/@/g, "/"),
        newPassword: e.target.repassword.value,
      })
      .then((res) => {
        navigate("/login");
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        const errorData = err.response?.data || {};
        setEmailError(errorData?.message);
        setPasswordError(
          passwordFirst === passwordSecond
            ? errorData?.errors?.NewPassword[0]
            : "Şifrələr eyni deyil"
        );
      });
  };
  const handleEmailChange = () => {
    setEmailError("");
  };
  const handleFirstPasswordChange = (e) => {
    setPasswordError("");
    setPasswordFirst(e.target.value);
  };
  const handlesecondPasswordChange = (e) => {
    setPasswordError("");
    setPasswordSecond(e.target.value);
  };
  const validations = {
    length: passwordFirst.length >= 8,
    upperCase: /[A-Z]/.test(passwordFirst),
    number: /[0-9]/.test(passwordFirst),
    match: passwordFirst === passwordSecond && passwordFirst.length > 0,
  };
  const isFormValid = Object.values(validations).every(Boolean);

  const togglePasswordVisibilityFirst = () => {
    setShowPasswordFirst((prev) => !prev);
  };

  const togglePasswordVisibilitySecond = () => {
    setShowPasswordSecond((prev) => !prev);
  };
  return (
    <div className="root">
     <div className="login-left">
        <img src={logo} alt="Logo" />
        <h3>
          Axtarılan mütəxəssisi bilik, bacarıq və səriştələr
          formalaşdırır. Onlara catmaq üçün isə illərlə işləmək gərəkdir, lakin
          biz sizə keyfiyyətli dərsliklərlə zamanınızı qısaldıb məqsədlərinizə
          daha tez çatmağınızda yardımçı oluruq.
        </h3>
      </div>
      <div className="login-box">
        <h2>Şifrə təyin edin!</h2>
        <form onSubmit={handleLogin}>
          <div className="txtb">
            <label>Email</label>
            <div className={`inputMain ${emailError ? "errorMessage" : ""}`}>
              <MailIcon />
              <input
                type="email"
                onChange={handleEmailChange}
                name="email"
                placeholder="Email"
                required
              />
            </div>
            {emailError && (
              <div className="error-message">
                <img src={errorImg} alt="Logo" />
                <p>{emailError}</p>
              </div>
            )}
          </div>
          <div className="txtb">
            <label>Şifrə</label>

            <div className={`inputMain ${passwordError ? "errorMessage" : ""}`}>
              <LockIcon />
              <input
                type={showPasswordFirst ? "text" : "password"}
                onChange={handleFirstPasswordChange}
                name="password"
                placeholder="Şifrə"
                className="loginPasword"
                required
              />
              {/* {passwordError && (
                <div className="error-message">
                  <img src={errorImg} alt="Logo" />
                  <p>{passwordError}</p>
                </div>
              )} */}
              <div onClick={togglePasswordVisibilityFirst}>
                {showPasswordFirst ? <CloseEyeIcon /> : <OpenEyeIcon />}
              </div>
            </div>
          </div>
          <div className="txtb">
            <label>Şifrənin təkrarı</label>
            <div className={`inputMain ${passwordError ? "errorMessage" : ""}`}>
              <LockIcon />
              <input
                type={showPasswordSecond ? "text" : "password"}
                onChange={handlesecondPasswordChange}
                className="loginPasword"
                name="repassword"
                placeholder="Şifrənin təkrarı"
                required
              />
              <div onClick={togglePasswordVisibilitySecond}>
                {showPasswordSecond ? <CloseEyeIcon /> : <OpenEyeIcon />}
              </div>
            </div>
            <div className="mainConditions">
              <div className="conditionsDiv">
                <div className="conditionsPassword">
                  <span>
                    {validations.number ? (
                      <CheckIcon className="check" />
                    ) : (
                      <CancelIcon className="cancel" />
                    )}
                  </span>
                  <p>Ən azı bir rəqəm olmalıdır</p>
                </div>
                <div className="conditionsPassword">
                  <span>
                    {validations.upperCase ? (
                      <CheckIcon className="check" />
                    ) : (
                      <CancelIcon className="cancel" />
                    )}
                  </span>
                  <p>Ən azı bir böyük hərf olmalıdır</p>
                </div>
                <div className="conditionsPassword">
                  <span>
                    {validations.length ? (
                      <CheckIcon className="check" />
                    ) : (
                      <CancelIcon className="cancel" />
                    )}
                  </span>
                  <p>Minimum uzunluq 8 simvol olmalıdır</p>
                </div>
                <div className="conditionsPassword">
                  <span>
                    {validations.match ? (
                      <CheckIcon className="check" />
                    ) : (
                      <CancelIcon className="cancel" />
                    )}
                  </span>
                  <p>Şifrələr eyni olmalıdır</p>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" disabled={!isFormValid}>
            Yadda saxla
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddPassword;
