import React, { useEffect, useRef, useState } from "react";
import "./UserProfileDetails.css";
import { FolderIcon, VideoIcon } from "../../assets/icons";
import FileDownloadIcon from "../../assets/icons/FileDownloadIcon";
import BarChart from "../../components/Charts/BarChart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../api/axiosClient";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DetailsTable from "./DetailsTable/DetailsTable";
import DetailsStats from "./DetailsStats/DetailsStats";

const UserProfileDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [userData, setUserData] = useState(null);
  const [timeData, setTimeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [loadingtime, setLoadingTime] = useState(true);
  const getActiveDateTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("dateTab")) || 0;
  };

  const [activeDateTab, setActiveDateTab] = useState(getActiveDateTabFromURL());
  const [tabNumber, setTabNumber] = useState(activeDateTab + 1);

  const handleDateTabClick = (index) => {
    setActiveDateTab(index);
    const params = new URLSearchParams(location.search);
    params.set("dateTab", index);
    navigate({ search: params.toString() });
    setTabNumber(index + 1);
    setLoadingProgress(true);
  };
  useEffect(() => {
    setTabNumber(activeDateTab + 1);
  }, [activeDateTab]);
  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      try {
        const response = await axiosClient.get(
          `/User/UserDetail/${id}?tab=${tabNumber}`
        );
        if (response.status === 200) {
          setUserData(response.data);
        } else {
          throw new Error("Error fetching profile data");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProfileData();
  }, [id]);

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        setLoadingProgress(true);
        const response = await axiosClient.get(
          `/User/UserDetail/${id}?tab=${tabNumber}`
        );
        if (response.status === 200) {
          setUserData(response.data);
        } else {
          throw new Error("Error fetching progress data");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingProgress(false);
      }
    };
    fetchProgressData();
  }, [tabNumber, id]);

  const educationLabels = {
    0: "Ali",
    1: "Orta",
    2: "Natamam Ali",
    3: "Tam Ali",
  };
  const tabRefs = useRef([]);
  const underlineRef = useRef(null);
  const getActiveTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("tab")) || 0;
  };
  const [activeTab, setActiveTab] = useState(getActiveTabFromURL());
  const handleTabClick = (index) => {
    setActiveTab(index);
    const params = new URLSearchParams(location.search);
    params.set("tab", index);
    navigate({ search: params.toString() });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    setActiveTab(getActiveTabFromURL());
  }, [location.search]);
  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = "none";
    }
  }, []);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = "transform 0.3s ease, width 0.3s ease";
    }
  }, [activeTab]);
  const fetchProgressData = async () => {
    try {
      setLoadingTime(true);
      const response = await axiosClient.get(
        `/User/UserDetailStatistic?userId=${id}&tab=${tabNumber}&skip=0&take=10`
      );

      if (response.status === 200) {
        setTimeData(response.data);
      } else {
        throw new Error("Error fetching progress data");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTime(false);
    }
  };

  useEffect(() => {
    fetchProgressData();
  }, [id, tabNumber]);
  return (
    <div className="profileDetailContainer">
      <div className="profile-sidebar">
        <div className="profile-top">
          {loading ? (
            <Skeleton circle={true} height={100} width={100} />
          ) : (
            <img
              src={userData?.photo}
              alt="Profile"
              className="profile-image"
            />
          )}
          <h2>
            {loading ? (
              <Skeleton width={150} />
            ) : (
              `${userData?.name} ${userData?.surname}`
            )}
          </h2>
          <p>{loading ? <Skeleton width={100} /> : userData?.profession}</p>
        </div>
        <hr />
        <div className="profile-bottom">
          <div className="profile-details">
            <div className="rowDetailInfo">
              <p>Mobil nömrə</p>
              <h4>
                {loading ? <Skeleton width={100} /> : userData?.phoneNumber}
              </h4>
            </div>
            <div className="rowDetailInfo">
              <p>Email</p>
              <h4>{loading ? <Skeleton width={200} /> : userData?.email}</h4>
            </div>
            <div className="rowDetailInfo">
              <p>Təhsil</p>
              <h4>
                {loading ? (
                  <Skeleton width={150} />
                ) : (
                  educationLabels[userData?.education]
                )}
              </h4>
            </div>
            <div className="rowDetailInfo">
              <p>Cins</p>
              <h4>
                {loading ? (
                  <Skeleton width={80} />
                ) : userData?.gender === 0 ? (
                  "Kişi"
                ) : (
                  "Qadın"
                )}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-main">
        <div className="profile-stats">
          <div className="dateRow">
            <h3>Göstəricilər</h3>
            <div className="analytic_date_tab2" style={styles.analyticDateTab}>
              <ul style={styles.dateTabMenu}>
                {["Gün", "Ay", "İl"].map((dateTab, index) => (
                  <li
                    key={index}
                    style={
                      activeDateTab === index
                        ? { ...styles.dateTab, ...styles.activeDateTab }
                        : styles.dateTab
                    }
                    onClick={() => handleDateTabClick(index)}
                  >
                    {dateTab}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="stats-grid">
            <div className="stat-item">
              <div className="stat-icon">
                <VideoIcon />
              </div>
              <p>Videoya baxılıb</p>
              <h4>
                {loading ? (
                  <Skeleton width={100} height={30} />
                ) : loadingProgress ? (
                  <Skeleton height={30} width={100} />
                ) : userData?.metrics?.video !== undefined ? (
                  userData?.metrics?.video
                ) : (
                  0
                )}
              </h4>
            </div>
            <div className="stat-item">
              <div className="stat-icon">
                <FolderIcon />
              </div>
              <p>Dərsliyə baxılıb</p>
              <h4>
                {loading ? (
                  <Skeleton width={100} height={30} />
                ) : loadingProgress ? (
                  <Skeleton height={30} width={100} />
                ) : userData?.metrics?.lesson !== undefined ? (
                  userData?.metrics?.lesson
                ) : (
                  0
                )}
              </h4>
            </div>
            <div className="stat-item">
              <div className="stat-icon">
                <FileDownloadIcon />
              </div>
              <p>Yükləmələr</p>
              <h4>
                {loading ? (
                  <Skeleton width={100} height={30} />
                ) : loadingProgress ? (
                  <Skeleton height={30} width={100} />
                ) : userData?.metrics?.download !== undefined ? (
                  userData?.metrics?.download
                ) : (
                  0
                )}
              </h4>
            </div>
          </div>
        </div>

        <div className="profile-progress">
          <div className="tabMenu2">
            <div className="tabUnderlineContainer2">
              {["Chart", "Table"].map((tab, index) => (
                <button
                  key={index}
                  ref={(el) => (tabRefs.current[index] = el)}
                  className={`tabMenuButton2 ${
                    activeTab === index ? "activeTab2" : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab}
                </button>
              ))}
              <div ref={underlineRef} className="tabUnderline2" />
            </div>
            <div>
              {loadingtime ? (
                <Skeleton width={100} height={30} />
              ) : (
                `${timeData?.time || ""} ${timeData?.hour ? "Saat" : ""} ${
                  timeData?.minute ? "Dəqiqə" : ""
                }`.trim()
              )}
            </div>
          </div>

          <div className="contentProfile">
            {activeTab === 0 && <DetailsStats id={id} tabNumber={tabNumber} />}
            {activeTab === 1 && <DetailsTable id={id} tabNumber={tabNumber} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  analyticDateTab: {
    marginTop: "0px",
    display: "flex",
    alignItems: "center",
  },
  dateTabMenu: {
    listStyleType: "none",
    padding: "0",
    display: "flex",
    gap: "5px",
  },
  dateTab: {
    padding: "8px",
    cursor: "pointer",
    width: "90px",
    borderRadius: "10px",
    fontSize: "15px",
    fontWeight: "400",
    fontFamily: "Mulish",
    color: "#333",
    background: "none",
    marginRight: "5px",
    margin: "0px 0px",
    textAlign: "center",
  },
  activeDateTab: {
    backgroundColor: "rgba(69, 147, 225, 1)",
    color: "#fff",
    borderColor: "transparent",
    borderRadius: "10px",
  },
};

export default UserProfileDetails;
