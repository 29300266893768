import React, { useContext, useEffect, useRef, useState } from "react";
import "./AnalytcsVideos.css";
import {
  DocsIcon,
  EyeIcon,
  FolderIcon,
  SearchIcon,
  StarIcon,
} from "../../../assets/icons";
import Dropdown from "../../../components/Dropdown/Dropdown";
import axiosClient from "../../../api/axiosClient";
import AnalytcsTable from "../AnalytcsTable/AnalytcsTable";
import { useMutation, useQuery } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import CustomModal from "../../../components/Modal/CustomModal";
import Videos from "../../Admin/Videos";
import AnalytcsVideosTop from "./AnalytcsVideosTop/AnalytcsVideosTop";
import AsyncSelectComponent from "../../../components/AsyncSelect/AsyncSelect";
import UseDebounce from "../../../shared/hooks/UseDebonce";
import DatePicker1 from "../../../components/DatePicker/DatePicker";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
const headers = [
  { text: "№" },
  { text: "Video" },
  { text: "İstifadəçilər" },
  { text: "Dəyərləndirmə" },
  { text: "Yükləmələr" },
  { text: "Baxış sayı" },
];
const AnalytcsVideos = React.forwardRef(
  ({ tabNumber, secondTabNumber, scrollableDivRef }, ref) => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedVideos, setSelectedVideos] = useState("Videolar");
    const [selectedRatings, setSelectedRatings] = useState("Dəyərləndirmə");
    const [selectedViews, setSelectedViews] = useState("Baxış sayı");
    const [minValueRate, setMinValueRate] = useState(null);
    const [maxValueRate, setMaxValueRate] = useState(null);
    const [minValueView, setMinValueView] = useState(null);
    const [maxValueView, setMaxValueView] = useState(null);
    const [searchFilters, setSearchFilters] = useState(null);
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultLessons, setDefaultLessons] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [dbClick, setDbClick] = useState(true);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const [skip, setSkip] = useState(0);
    const [take] = useState(10);
    const [hasMoreData, setHasMoreData] = useState(true);
    const loadingMoreRef = useRef(false);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = UseDebounce(searchTerm, 300);
    const [mainTab,setMainTab]=useState(tabNumber)
    const [dates, setDates] = useState([]);
    const { ActiveUsers } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(null);
 const navigate=useNavigate()
 const userListRef = useRef(null); 

    useEffect(() => {
      setMainTab(tabNumber)
      scrollableDivRef?.scrollTo(0, 0);
      setFormData([]);
      setSkip(0);
      setHasMoreData(true);
    }, [tabNumber]);

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axiosClient.get(
            `/UserVIdeoAndLessonRating/GetVideo?tab=${mainTab}&skip=${skip}&take=${take}`,
            { params: searchFilters }
          );

          if (response.status === 200) {
            if (response.data.length === 0 && skip > 0) {
              setHasMoreData(false);
            } else {
              setFormData((prev) => {
                const newData = [...prev, ...response.data];
                const uniqueData = Array.from(
                  new Set(newData.map((item) => item.id))
                ).map((id) => newData.find((item) => item.id === id));
                return uniqueData;
              });
              setHasMoreData(response.data.length === take);
            }
          } else {
            throw new Error("Error fetching profile data");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
        fetchData();
    }, [mainTab, skip,searchFilters]);
           
  

    useEffect(() => {
      const handleScroll = () => {
        if (
          scrollableDivRef &&
          !loading &&
          hasMoreData &&
          scrollableDivRef.scrollTop + scrollableDivRef.clientHeight >=
          scrollableDivRef.scrollHeight - 150


        ) {
          if (!loadingMoreRef.current) {
            loadingMoreRef.current = true;
            setSkip((prevSkip) => prevSkip + 1);
          }
        }
      };
      if (scrollableDivRef) {
        scrollableDivRef.addEventListener("scroll", handleScroll);
        return () => {
          scrollableDivRef.removeEventListener("scroll", handleScroll);
        };
      }
    }, [scrollableDivRef, loading, hasMoreData]);
    useEffect(() => {
      if (!loading) {
        loadingMoreRef.current = false;
      }
    }, [loading]);
    const { mutate: filterDataMutation, isLoading: isFiltering } = useMutation({
      mutationFn: async (filters) => {
        setLoading(true);
        try {
          const response = await axiosClient.get(
            `/UserVIdeoAndLessonRating/FilterVideo?tab=${tabNumber}`,
            { params: filters }
          );
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error("Error fetching filtered data");
          }
        } finally {
          setLoading(false);
        }
      },
      onSuccess: (data) => {
        setFormData(data);
        setSkip(0);
        setHasMoreData(data.length === take);
      },
    });
    const handleSearchClick = () => {
      const filters = {
        videoid:
          selectedVideos && selectedVideos.value !== undefined
            ? selectedVideos.value
            : null,
            minRate: minValueRate || null,
            maxRate: maxValueRate || null,
            minView: minValueView || null,
            maxView: maxValueView || null,
            min: dates ? dates.min : null,
            max: dates ? dates.max : null,
      };
      setSearchFilters(filters);
      setFormData([]);
      setLoading(true);
    };
    const handleSelectRatings = (item) => setSelectedRatings(item);
    const handleSelectViews = (item) => setSelectedViews(item);
    const handleDropdownClick = (dropdownId, isOpen) =>
      setOpenDropdown(isOpen ? dropdownId : null);
    const handleSelectChange = (selectedOption) => {
      if (selectedOption?.value === "view_all") {
        openModal();
      } else {
        setSelectedVideos(selectedOption);
      }
    };
    useEffect(() => {
      const selectLessonData = async () => {
        try {
          const response = await axiosClient.get(
            `/UserVIdeoAndLessonRating/AllSingleVideo?tab=${tabNumber}`
          );
          if (response.status === 200) {
            const lessons = response.data.map((item) => ({
              label: item.title,
              value: item.id,
            }));
            setDefaultLessons([...lessons]);
            if (lessons.length > 0) {
              // setSelectedVideos(lessons[0]);
            } else {
              setSelectedVideos(null);
            }
          } else {
            throw new Error("Error fetching lesson data");
          }
        } catch (error) {
          console.error("Error fetching lesson data:", error);
          setSelectedVideos(null);
        }
      };
      selectLessonData();
    }, [tabNumber]);
    const filterLessons = async (inputValue) => {
      if (!inputValue) return [];

      try {
        const response = await axiosClient.get(
          `/UserVIdeoAndLessonRating/FilterVideoByName?`,
          { params: { title: inputValue, tab: tabNumber } }
        );
        if (response.status === 200) {
          const lessons = response.data.map((item) => ({
            label: item.title,
            value: item.id,
          }));
          setDefaultLessons(lessons);
          return lessons;
        }
      } catch (error) {
        console.error("Error fetching filtered lessons", error);
      }
      return [];
    };
    useEffect(() => {
      if (debouncedSearchTerm) {
        filterLessons(debouncedSearchTerm);
      } else {
        setDefaultLessons([]);
      }
    }, [debouncedSearchTerm]);
    const toggleList = (id) => {
      setIsOpen(id);
    };
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (userListRef.current && !userListRef.current.contains(event.target)) {
          setIsOpen(null);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    const handleProfile = (id) => {
      navigate(`/details/${id}`);
    };
    return (
      <div className="mainVideos">
        <CustomModal isOpen={isModalOpen} onClose={closeModal}>
          <Videos dbClick={dbClick} closeModal={closeModal} />
        </CustomModal>
        <AnalytcsVideosTop
          tabNumber={tabNumber}
          secondTabNumber={secondTabNumber}
        />
        <div className="DropdDownMain">
          <div className="dropDowns">
            <AsyncSelectComponent
              placeholder={"Videolar"}
              handleChange={handleSelectChange}
              filterData={filterLessons}
              defaultOptions={defaultLessons}
              onInputChange={(inputValue) => setSearchTerm(inputValue)}
            />
            <Dropdown
              title={selectedRatings}
              minmax={[minValueRate, maxValueRate]}
              isOpen={openDropdown === "ratings"}
              onClick={(isOpen) => handleDropdownClick("ratings", isOpen)}
              onSelect={handleSelectRatings}
              setMinValue={setMinValueRate}
              setMaxValue={setMaxValueRate}
              rating={true}
            />
            <Dropdown
              title={selectedViews}
              minmax={[minValueView, maxValueView]}
              isOpen={openDropdown === "views"}
              onClick={(isOpen) => handleDropdownClick("views", isOpen)}
              onSelect={handleSelectViews}
              setMinValue={setMinValueView}
              setMaxValue={setMaxValueView}
            />
            <DatePicker1 setData={setDates} />
            <button
              className="search-button"
              onClick={handleSearchClick}
              disabled={isFiltering}
            >
              <p>Axtar</p>
              <span>
                <SearchIcon />
              </span>
            </button>
          </div>
        </div>
        <AnalytcsTable headers={headers} data={formData} loading={loading}>
          {formData && formData.length > 0
            ? formData.map((elem, i) => (
                <tr key={elem?.id} className={i >= formData.length - 3 ? 'highlight-row' : ''} >
                  <td className="analytc_table_id">{i + 1}</td>
                  <td>
                    <div className="analytc_table_image_div">
                      <div className="analytc_table_image_divs">
                        <FolderIcon />
                      </div>
                      <span>{elem?.title}</span>
                    </div>
                  </td>
                  <td >
                    <div className="avatar-stack" onClick={() => toggleList(i)}>
                      {elem?.users?.slice(0, 3).map((avatar, index) => (
                        <img
                          key={index}
                          src={avatar.photo}
                          alt={`Avatar ${index + 1}`}
                          className="avatar"
                        />
                      ))}
                      {elem?.users.length > 3 && (
                        <span className="extra-count2">
                          +{elem.users.length - 3}
                        </span>
                      )}
                    </div>
                    {isOpen == i && (
                      <div className="user-list2" ref={userListRef}>
                        <h5>Istifadəçilər</h5>
                        <ul>
                          {elem?.users?.map((user, index) => (
                            <li onClick={()=>handleProfile(user.id)} >
                              <img src={user.photo} className="avatar" />
                              <p>{user.fullName}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </td>
                  <td>
                    <div
                      className="analytc_table_rate_div"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>
                        <StarIcon />
                      </span>
                      <span>{elem?.rate}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      className="analytc_table_doc_div"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>
                        <DocsIcon />
                      </span>
                      <span>{elem?.download}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      className="analytc_table_rate_div"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>
                        <EyeIcon />
                      </span>
                      <span>{elem?.viewerCount}</span>
                    </div>
                  </td>
                </tr>
              ))
            : null}
        </AnalytcsTable>
      </div>
    );
  }
);
export default AnalytcsVideos;
