import axios from 'axios';
import { toast } from 'react-toastify';

const apiUrl = 'https://eduapi.siesco.az/api'
const axiosClient = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});
axiosClient.interceptors.request.use(
  async (config) => {
    if (config.method === "post" || config.method === "put" || config.method === "delete") {
      toast.dismiss()
      toast.loading("Zəhmət olmasa gözləyin")
    }
    const token = localStorage.getItem('authState');
    const a=JSON.parse(token)
    if (token) {
      config.headers.Authorization = `Bearer ${a.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosClient.interceptors.response.use(
  (response) => {
    if (response.config.method === "post" || response.config.method === "put" || response.config.method === "delete") {
      toast.dismiss()
      toast.success("Əməliyyat uğurla yerinə yetirildi", {
        autoClose: 1300, 
      })
    }
    return response;
  },
  async (error) => {
    toast.dismiss()
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          const { data } = await axios.post('/Auth/LoginWithRefreshToken', { token: refreshToken }, { baseURL: apiUrl });
          localStorage.setItem('accessToken', data.accessToken);
          axiosClient.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
          return axiosClient(originalRequest);
        } catch (refreshError) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('userFullName');
          localStorage.removeItem('userStatus');
          window.location.href = '/login';
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
