import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, Filler } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './Chart.css'; // CSS faylını idxal edin

// Chart.js konfiqurasiya
ChartJS.register(Title, Tooltip, Legend, ArcElement, Filler, ChartDataLabels);

const PieChart = ({ statsData }) => {
  const labels = ['Videolar', 'Dərsliklər', 'Yükləmələr'];
  const values = [
    statsData?.videos || 0,
    statsData?.lesson || 0,
    statsData?.download || 0
  ];

  // Check if all values are zero
  const allZero = values.every(value => value === 0);

  const filteredData = allZero
    ? labels.map((label) => ({ label, value: 33.33 })) // Set each to 33.33% if all are zero
    : values.map((value, index) => value > 0 ? { label: labels[index], value } : null).filter(Boolean);

  const data = {
    labels: filteredData.map(item => item.label),
    datasets: [
      {
        data: filteredData.map(item => item.value),
        backgroundColor: [
          'rgba(52, 179, 241, 0.37)',
          'rgba(8, 56, 79, 1)',
          'rgba(8, 123, 179, 1)'
        ],
        borderColor: '#fff',
        borderWidth: 2,
      }
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            family: 'Mulish',
            size: 12,
            weight: '400',
          },
          color: '#000000',
          usePointStyle: true,
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}`;
          }
        }
      },
      datalabels: {
        display: !!statsData, 
        color: 'white',
        formatter: (value, context) => {
          const total = context.chart._metasets[0].total;
          const percentage = ((value / total) * 100).toFixed(2);
          return `${percentage}%`;
        },
        font: {
          weight: 'bold',
          size: 14,
        },
        anchor: 'center',
        align: 'center',
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: '0%'
  };

  return (
    <div className="pie-chart-container">
      <Pie data={data} options={options} width={257} height={257} />
    </div>
  );
};

export default PieChart;
