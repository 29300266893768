import React, { useContext } from "react";
import "../App.css";
import Nav from "./Nav";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { DownArrowIcon } from "../assets/icons";
import MainSearch from "./MainSearch/MainSearch";

function Layout() {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const profilePath = () => {
    navigate("/profile");
  };
  return (
    <>
      <Nav />
      <main>
        <header>
         <MainSearch/>
          <div
            className="spanProfile"
            onClick={profilePath}
            style={{ cursor: "pointer" }}
          >
             <div className="avatarPhoto">
              <img src={auth.photo} alt="" />
            </div>
            <p>{auth.userFullName}</p>
          </div>
        </header>
        <Outlet />
      </main>
    </>
  );
}

export default Layout;
