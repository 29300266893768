import React, { useState } from 'react';
import { DatePicker } from 'antd';
import "./DatePicker.css";  

const { RangePicker } = DatePicker;

const DatePicker1 = ({ setData }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(['date', 'date']);

  const handlePanelChange = (dates, currentMode) => {
    if (currentMode[0] === 'date' && currentMode[1] === 'date') {
      setMode(['date', 'date']);
    }
  };
  const formatDateToString = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}.${month}.${year}`; 
  };
  const handleChange = (dates) => {
    const [start, end] = dates;
    const formattedStart = start ? formatDateToString(start) : null; 
    const formattedEnd = end ? formatDateToString(end) : null;       
    setData({ min: formattedStart, max: formattedEnd });
  };

  return (
    <div style={{ padding: '3px', display: "flex", justifyContent: "center" }}>
      <div style={{ position: 'relative' }} className='datePicker'>
        <RangePicker
          open={open}
          onOpenChange={(o) => setOpen(o)}
          mode={mode}
          onPanelChange={handlePanelChange}
          onChange={handleChange}
          style={{ width: 'auto', border: "none" }}
          popupClassName="custom-range-picker"
          picker="date"
          dropdownRender={(menu) => (
            <div>{menu}</div>
          )}
          inputStyle={{
            color: '#000000',
            fontWeight: 600,
            fontFamily: 'Mulish',
            lineHeight: '20px',
            fontSize: '16px'
          }}
          placeholder={['Start Date', 'End Date']}
        />
      </div>
    </div>
  );
};

export default DatePicker1;
