import React, { useContext, useState } from "react";
import pin from "../images/pin.svg";
import logo from "../images/SiescoLogo.svg"
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import AnalyticIcon from "../assets/icons/AnalyticIcon";
import PinIcon from "../assets/icons/PinIcon";

function Nav() {
  const { auth, logout } = useContext(AuthContext);
  const [pinned, setPinned] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = () => {
    logout();
    handleClose();
  };
  const handleClose = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <nav className={pinned ? "pin" : ""}>
      {dropdownOpen && (
        <ul className="navDropdownMenu">
          <li onClick={handleClose}>
            <NavLink to="/profile" exact activeClassName="active">
              <div className="spanUserProfile" style={{ cursor: "pointer" }}>
                <div className="avatarPhoto">
                  <img src={auth.photo} alt="" />
                </div>
                <p>{auth.userFullName}</p>
              </div>
            </NavLink>
          </li>
          <li onClick={handleLogout}>
            <NavLink to="/login">
              <p className="logoutFromNav">
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
                Çıxış
              </p>
            </NavLink>
          </li>
        </ul>
      )}
      <div className="nav-header">
        <Link to="//siesco.az">
          <img src={logo} alt="Logo" />
        </Link>
        <div className="ActivePin" onClick={() => setPinned(!pinned)}>
          <PinIcon active={pinned?true:false}  />
        </div>
      </div>
      <ul>
        {auth.userStatus === "Admin" && (
          <li>
            <NavLink to="/users">
              <i className="fa fa-user"></i>
              <span>İstifadəçilər</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to="/">
            <i className="fa-brands fa-youtube"></i>
            <span>Videolar</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/documents">
            <i className="fa-solid fa-book"></i>
            <span>Dərsliklər</span>
          </NavLink>
        </li>
        {auth.userStatus === "Admin" && (
        <li>
          <NavLink className="nav_analytc" to="/analitika">
            <AnalyticIcon />
            <span>Analitika</span>
          </NavLink>
        </li>)}
        <li onClick={handleLogout} className="displayNoneNavMenu">
          <NavLink className="nav_analytc" to="/login">
            <i className="fa-solid fa-arrow-right-from-bracket"></i>
            <span>Çıxış</span>
          </NavLink>
        </li>
        <li
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="displayBlockNavMenu"
        >
          <i className="fa-solid fa-gear"></i>
          <span>Çıxış</span>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
