
import * as React from "react";
import PropTypes from "prop-types";

const AnalyticIcon = ({
  size = 26,
  strokeWidth = 1,
  color = "currentColor",
  stroke="black",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.25 25.4559H3.75" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
    <path d="M12.5 20.4559V12.4559C12.5 11.3514 11.6046 10.4559 10.5 10.4559H9.5C8.39543 10.4559 7.5 11.3514 7.5 12.4559V20.4559"  stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
    <path d="M22.5 20.4559V7.45593C22.5 6.35136 21.6046 5.45593 20.5 5.45593H19.5C18.3954 5.45593 17.5 6.35136 17.5 7.45593V20.4559"  stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
    </svg>
);

AnalyticIcon.displayName = "Check";

AnalyticIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default AnalyticIcon;
