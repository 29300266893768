import React, { useContext, useEffect, useRef, useState } from "react";
import ApexChart from "../../../../components/Charts/StatisticChart";
import PieChart from "../../../../components/Charts/PieChart";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AuthContext } from "../../../../context/AuthContext";
import { color } from "@mui/system";
import "../AnalytcsUsers.css"
import { useNavigate } from "react-router-dom";
const AnalytcsUserTop = ({
  metricData,
  tabNumber,
  secondTabNumber,
}) => {
  const { ActiveUsers } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
 const navigate=useNavigate()
  
  const userListRef = useRef(null); 
  const statsData = {
    videos: metricData?.video?.count || 0,
    lesson: metricData?.lesson?.count || 0,
    download: metricData?.download?.count || 0,
  };
  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userListRef.current && !userListRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleProfile = (id) => {
    navigate(`/details/${id}`);
  };
  return (
    <div className="analytc_users_top">
      <div className="analytc_users_top_left">
        <div className="analytc_users_top_left_top">
          <span>Aktiv istifadəçilər</span>
          <div className="analytc_users_top_left_top_users" ref={userListRef}>
            <div className="avatar-stack" onClick={toggleList}>
              {ActiveUsers.slice(0, 3).map((avatar, index) => (
                <img
                  key={index}
                  src={avatar?.photo}
                  alt={`Avatar ${index + 1}`}
                  className="avatar"
                />
              ))}
              {ActiveUsers.length > 3 && (
                <span className="extra-count">+{ActiveUsers.length - 3}</span>
              )}
            </div>
              {isOpen && (
                <div className="user-list" >
                  <h3>Onlayn istifadəçilər</h3>
                  <ul>
                    {ActiveUsers.map((user, index) => (
                      <li onClick={()=>handleProfile(user.userId)}>
                        <img src={user.photo} className="avatar" />
                        <p>{user.fullName}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
          </div>
        </div>
        <div style={{ height: "200px" }}>
          <div className="analytc_users_top_left_top_users_count">
            <span>{ActiveUsers.length}</span>
          </div>
          <div className="barChart">
            <ApexChart
              tabNumber={tabNumber}
              secondTabNumber={secondTabNumber}
            />
          </div>
          <div className="analytc_users_top_left_top_date">
            <span>Tarix</span>
          </div>
        </div>
      </div>
      <div className="analytc_users_top_right">
        <div className="analytc_users_top_right_top">
          <div className="userViewsStats">
            <h2>İstifadəçi göstəriciləri</h2>
          </div>
          <span>Statistikalar</span>
        </div>
        <div className="analytc_users_top_right_bottomMain">
          <div className="analytc_users_top_right_bottom">
            <div className="analytc_users_top_right_bottom_box">
              <span className="analytc_users_top_right_bottom_box_span1">
                Videolar
              </span>
              <span
                className="analytc_users_top_right_bottom_box_span2"
              >
                {metricData?.video ? (
                  metricData.video.count !== undefined &&
                  metricData.video.count !== null ? (
                    metricData.video.count
                  ) : (
                    <Skeleton width={60} height={20} />
                  )
                ) : (
                  <Skeleton width={60} height={20} />
                )}
              </span>
              <span className="analytc_users_top_right_bottom_box_span3">
                {metricData?.video ? (
                  metricData.video.percentString !== undefined &&
                  metricData.video.percentString !== null ? (
                    <>
                      {metricData.video.percentString}
                      <span>%</span>
                    </>
                  ) : (
                    <Skeleton width={60} height={15} />
                  )
                ) : (
                  <Skeleton width={60} height={15} />
                )}
              </span>
            </div>
            <div className="analytc_users_top_right_bottom_box">
              <span className="analytc_users_top_right_bottom_box_span1">
                Dərsliklər
              </span>
              <span
                className="analytc_users_top_right_bottom_box_span2"
              >
                {metricData?.lesson ? (
                  metricData.lesson.count !== undefined &&
                  metricData.lesson.count !== null ? (
                    metricData.lesson.count
                  ) : (
                    <Skeleton width={60} height={20} />
                  )
                ) : (
                  <Skeleton width={60} height={20} />
                )}
              </span>
              <span className="analytc_users_top_right_bottom_box_span3">
                {metricData?.lesson ? (
                  metricData.lesson.percentString !== undefined &&
                  metricData.lesson.percentString !== null ? (
                    <>
                      {metricData.lesson.percentString}
                      <span>%</span>
                    </>
                  ) : (
                    <Skeleton width={60} height={15} />
                  )
                ) : (
                  <Skeleton width={60} height={15} />
                )}
              </span>
            </div>
            <div className="analytc_users_top_right_bottom_box">
              <span className="analytc_users_top_right_bottom_box_span1">
                Yükləmələr
              </span>
              <span
                className="analytc_users_top_right_bottom_box_span2"
              >
                {metricData?.download ? (
                  metricData.download.count !== undefined &&
                  metricData.download.count !== null ? (
                    metricData.download.count
                  ) : (
                    <Skeleton width={60} height={20} />
                  )
                ) : (
                  <Skeleton width={60} height={20} />
                )}
              </span>
              <span className="analytc_users_top_right_bottom_box_span3">
                {metricData?.download ? (
                  metricData.download.percentString !== undefined &&
                  metricData.download.percentString !== null ? (
                    <>
                      {metricData.download.percentString}
                      <span>%</span>
                    </>
                  ) : (
                    <Skeleton width={60} height={15} />
                  )
                ) : (
                  <Skeleton width={60} height={15} />
                )}
              </span>
            </div>
          </div>
          <div className="pie-chart-container">
            {metricData && Object.keys(metricData).length > 0 && (
              <PieChart statsData={statsData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalytcsUserTop;
